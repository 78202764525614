<template>
  <div class="h-full flex flex-col">
    <loader :loading="loading" :backdrop="true" />
    <div class="flex box-size overflow-auto">
      <div class="w-full h-full items-center overflow-auto p-8">
        <div class="form-row w-full">
          <p class="font-bold">select the report format:</p>
        </div>
        <div class="form-row w-full">
          <button
            class="transcript text-gray-500 focus:outline-none font-semibold py-2 px-4 border button-color"
            :class="{ activeCatg: 'transcript' === selectedCatg }"
            @click="selectedCatg = 'transcript'">
            transcript report
          </button>
          <button
            class="mismatchtext-gray-500 focus:outline-none font-semibold py-2 px-4 border button-color"
            :class="{ activeCatg: 'mismatch' === selectedCatg }"
            @click="selectedCatg = 'mismatch'">
            mismatch report
          </button>
        </div>
        <div class="w-full h-full">
          <div class="w-full">
            <DateRangePicker
              :opens="'left'"
              :ranges="false"
              :autoApply="true"/>
          </div>
          <div class="w-full">
            <div class="overflow-auto py-5">
              <div class="text-left mb-5 ">
                <p class="font-bold">report format</p>
                <span class="form-hint">your download will begin automatically after clicking the export to excel button</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-footer :footer="footer" :tertiary="cancel" :primary="onDownload" />
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import GlobalResponsesMixin from '@/components/digital_human/GlobalResponsesMixin'
import ReportsMixin from '@/components/digital_human/ReportMixin'
import DateRangePicker from '@/components/digital_human/DateRangePicker';
import ModalNavigation from '@/mixins/ModalNavigation'
import ModalFooter from '@/components/ui/modals/ModalFooter'
import NotifyMixin from '@/mixins/NotifyMixin'
import Loader from '@/components/ui/Loader'
import EventBus from "@/utils/EventBus"

export default {
  components: { Loader, ModalFooter, DateRangePicker },
  mixins: [AuthMixin, ModalNavigation, NotifyMixin, GlobalResponsesMixin, ReportsMixin],
   data() {
    return {
      footer: {
        primaryButton: 'export to excel',
        tertiaryButton: 'cancel',
      },
      fitleExtention: '',
      selectedCatg: 'transcript',
    }
  },
  methods: {
    download(extention) {
      this.fitleExtention = extention;
      if (this.selectedCatg !== '')
        EventBus.emit('show-modal-download', { title: 'Download PDF', downloadStarted: 'download started'});
    },
    downloadProcess(data, extentionFile) {
      const fileName = this.selectedCatg === 'transcript' ? 'transcript-report' : 'mismatch-report';
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.${extentionFile}`);
      document.body.appendChild(link);
      link.click();
    },
    generateExcelReport() {
      if (this.selectedCatg === 'transcript') {
        this.$dhDataProvider.get('transcriptReport', { communityId: this.community?.id, startDate: this.startDate, endDate: this.endDate,
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'Content-disposition': 'attachment',
            'format_file': 'xlsx'
          }})
          .then(response => {
            if (this.fitleExtention.trim() !== '') {
              this.fitleExtention = ''
              this.downloadProcess(response, 'xlsx');
            }
          })
          .catch((error) => this.notifyError(error))
          .finally(() => this.hideDownloadIndicator())
      } else {
        this.$dhDataProvider.get('mismatchReport', { communityId: this.community?.id, startDate: this.startDate, endDate: this.endDate,
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'Content-disposition': 'attachment',
            'format_file': 'xlsx'
          }})
          .then(response => {
            if (this.fitleExtention.trim() !== '') {
              this.fitleExtention = ''
              this.downloadProcess(response, 'xlsx');
            }
          })
          .catch((error) => this.notifyError(error))
          .finally(() => this.hideDownloadIndicator())
      }
    },
    setCategoryValue(value) {
      this.cleanState('category')
      if (!value) return
      this.setCategory(value)
    },
    onDownload() {
    if (!this.community) {
      this.notifyError(
        'please select a community to continue, then refresh the browser'
      )
    } else {
      this.download('xlsx')
    }
    },
    cancel() {
      this.redirect('responses.index')
    },
  },
  validations: {
    responseText: 'required',
  },
  created() {
     if (!this.community) {
      this.notifyError(
        'please select a community to continue, then refresh the browser'
      )
    } else {
      this.initValidator()
    }
  },
  mounted() {
    EventBus.on('download-pdf', () => {
      switch (this.fitleExtention) {
        case 'xlsx':
          this.generateExcelReport()
          break;
        default:
          this.fitleExtention = ''
          break;
      }
    })
  }
}
</script>
<style scoped>
  .button-color{
    border-color: var(--highlightColor500);
    background-color: var(--highlightColor300);
  }
  .form {
    width: 55%;
  }
  ul:before {
    content: attr(aria-label);
    font-size: 1rem;
    font-weight: bold;
    color: black;
  }
  ul > li {
    @apply py-3 font-light text-black;
  }
  .activeCatg {
    background-color: var(--highlightColor500);
    @apply text-white border-transparent;
  }
  .box-size {
    height: 35rem;
  }
</style>
